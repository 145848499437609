import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { db, storage } from '../context/authFirebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../context/authFirebase';
import { useNavigate, Link } from 'react-router-dom';

export default function AddPost() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [successPost, setSuccessPost] = useState('');
  const [errorPost, setErrorPost] = useState('');
  const [perc, setPerc] = useState(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  // Upload image
  const handleFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName('');
    }

    const name = new Date().getTime() + selectedFile.name;
    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPerc(progress);
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      }, 
      (error) => {
        setErrorPost(error.message);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFile(downloadURL);
        });
      }
    );
  }

  // Handle title
  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  // Handle body
  const handleBody = (e) => {
    setBody(e);
  }

  // Handle category
  const handleCategory = (e) => {
    setCategory(e.target.value);
  }

  // Write post
  const writePost = async () => {
    if (title && body && category && file) {
      try {

        const currentDate = new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        
        const docRef = await addDoc(collection(db, 'posts'), {
          title: title,
          body: body,
          category: category,
          image: file,
          createdAt: currentDate,
          timestamp: serverTimestamp()
        });

        setSuccessPost('Post created successfully!');
        setTitle('');
        setBody('');
        setCategory('');
        setFile(null);
        setFileName('');
        setPerc(null);

        navigate('/dashboard');
        console.log('Document written with ID: ', docRef.id);
      } catch (error) {
        setErrorPost(error.message);
      }
    } else {
      setErrorPost('Please fill all fields!');
    }
  }

  // Handle sign out
  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate('/login')
    }).catch((error) => {
      console.log(error.message);
    });
  }

  // Auth state change
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user);
      } else {
        // User is signed out
        navigate('/login');
      }
    });
  }, [navigate]);

  // Logout
  const logout = () => {
    handleSignOut();
  }

  return (
    <div className="mx-auto relative font-sans">
      <div className='h-12 lg:h-20 w-full bg-mauve flex justify-center md:justify-end px-8 lg:px-20'>
        <ul class="flex items-center text-white gap-10 justify-between mt-0 rounded-lg md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
          {currentUser ? (
            <>
            <span className="block py-2 pl-3 pr-4 md:text-blueblack  md:border-0 md:p-0 ">{currentUser.email}</span>
            <button onClick={logout} className='block py-2 md:px-4 rounded-3xl pl-3 pr-4 bg-gold md:text-white md:hover:bg-seablue'>Logout</button>
            </>
          ) : (
            <Link to="/login"></Link>
          )}
        </ul>
      </div>
      <div class="px-5 lg:px-20 py-10 lg:py-10">
        <Link to="/dashboard">
          <button class='mb-5 lg:mb-8 py-2 px-4 bg-blue-800 hover:bg-blue-600 rounded text-white font-semibold'>&#129048; Back</button>
        </Link>
        <div className='grid gap-10 lg:flex lg:justify-between mx-auto lg:mb-10 text-blueblack'>
          {/* Title & Content */}
          <div className='lg:h-auto lg:w-2/3 grid gap-5'>
            {/* Title */}
            <div className='w-full'>
              <input 
                className='w-full p-2 border border-gray-400 bg-transparent'
                type="text"
                placeholder='Title'
                value={title}
                onChange={handleTitle}
                id='title'
              />
            </div>
            {/* Content */}
            <div className=''>
              <ReactQuill 
                className='md:h-40'
                theme="snow"
                // ref={editor}
                value={body}
                id='body'
                onChange={handleBody}
              />
            </div>
          </div>
            
          {/*  */}
          <div className='grid gap-10 lg:w-1/3 lg:h-40'>
            {/* Category */}
            <div className='grid gap-2 border border-gray-400 p-2'>
              <h3 className='font-bold text-xl'>Category</h3>
              <div className='grid justify-between'>
                <div className='flex gap-3 items-center'>
                  <input 
                    type="radio"
                    name="category"
                    value="blog"
                    id="blog"
                    onChange={handleCategory}
                  />
                  <label htmlFor='blog'>Blog</label>
                </div>
                <div className='flex gap-3 items-center'>
                  <input
                    type="radio"
                    name="category"
                    value="gallery"
                    id="gallery"
                    onChange={handleCategory}
                    />
                  <label htmlFor='gallery'>Gallery</label>
                </div>
              </div>
            </div>
            
            {/* Image Upload */}
            <div className='grid gap-2 border border-gray-400 p-2 max-h-auto'>
            <div className='flex justify-between'>
              <div>
                <input 
                  className='hidden'
                  type="file"
                  id="file"
                  placeholder='Image'
                  accept='image/*'
                  onChange={handleFile}
                  // name='file'
                />
                <label className='cursor-pointer' htmlFor="file">Upload Image</label>
                {fileName && <><div className='italic text-xs'>{fileName}</div></>}
                {/* {perc !== null && <><div>{perc}%</div></>} */}
              </div>
              <button 
                className='bg-blue-800 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-sm disabled:bg-blue-300 disabled:cursor-not-allowed'
                onClick={writePost}
                disabled={perc !== null && perc < 100}
              >Submit</button>
            </div>
            {errorPost && <><div className='text-red-600'>{errorPost}</div></> }
            {successPost && <><div>{successPost}</div></> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
