import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../context/authFirebase';
import { doc, getDoc } from 'firebase/firestore';

export default function BlogPost({ blogData }) {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postRef = doc(db, 'posts', id); // Reference the 'posts' collection and the specific post by ID
        const docSnap = await getDoc(postRef);

        if (docSnap.exists()) {
          setBlog(docSnap.data());
        } else {
          console.log('Post not found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id]);

  if (!blog) return <h1 className='text-center font-bold text-xl'>Blog Post Not Found</h1>
  return (
    <div className='px-8 lg:px-24'>
      <h1 className='text-center font-bold text-xl'>Blog</h1>
      <div className="">
        <div className="h-fit overflow-hidden grid justify-items-center max-w-sm lg:min-w-28 border border-white rounded-lg shadow">
          <img className='' src={blog.image} alt={blog.title} />
        </div>
        <div className='grid justify-start gap-3'>
          <div className='grid'>
            <h1 className='font-bold text-xl md:text-2xl'>{blog.title}</h1>
            <span className='text-sm text-gray-700'>{blog.createdAt}</span>
          </div>
          {/* <p>{blog.body}</p> */}
          <div dangerouslySetInnerHTML={{ __html: blog.body}} />
        </div>
      </div>
    </div>
  )
}