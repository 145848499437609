import './App.css';
import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import BlogPost from './component/BlogPost';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddPost from './pages/AddPost';
import EditPost from './pages/EditPost';
import Gallery from './pages/Gallery';
import { auth } from '../src/context/authFirebase';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    })
  })

  const Layout = () => {
    return (
      <div className="">
        <Navbar/>
        <div className=''>
          <Outlet/>
        </div>
        <Footer/>
      </div>
    );  
  };

  const ProtetedRoute = ({ element }) => {
    if(user) {
      return element;
    }else{
      return (
        <div className="grid place-items-center bg-gold text-white h-screen py-80 md:py-96 lg:py-56">
          <p className="md:text-3xl">Please log in to access this page.</p>
          <Link to="/login">
            <button className="bg-mauve hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-4">
              Login
            </button>
          </Link>
        </div>
      );
    }
  };

  const BrowserRouter = createBrowserRouter([
    {
      path: '/',
      element: <Layout/>,
      children: [
        { path: '/', element: <Home/> },
        { path: '/about', element: <About/> },
        { path: '/blog', element: <Blog/> },
        { path: '/contact', element: <Contact/> },
        { path: '/donate', element: <Donate/> },
        { path: '/gallery', element: <Gallery/> },
        { path: '/blog/:id', element: <BlogPost/> },
      ]
    },
    { path: '/login', element: <Login/> },
    { path: '/dashboard', element: <ProtetedRoute element={<Dashboard/>}/> },
    { path: '/add-post', element: <ProtetedRoute element={<AddPost/>}/> },
    { path: '/edit-post/:id', element: <ProtetedRoute element={<EditPost/>}/> },
  ]);

    ReactDOM.createRoot(document.getElementById("root")).render(
    <div>
      <RouterProvider router={BrowserRouter} />
    </div>
  )
}

export default App;
