import React from 'react';
import GMLLogo from '../images/gml.png';
import { Link, useLocation } from 'react-router-dom';
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";

export default function StickyNavbar() {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const closeMobileNav = () => {
    setOpenNav(false);
  };
 
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-12">
      <Typography
        as="li"
        variant="small"
        className={`p- font-normal text-mauve ${location.pathname === '/' ? 'lg:border-b-2 border-mauve' : ''}`}
        onClick={closeMobileNav}
      >
        <Link to="/" className="flex items-center">Home</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className={`p- font-normal text-mauve ${location.pathname === '/about' ? 'lg:border-b-2 border-mauve' : ''}`}
        onClick={closeMobileNav}
      >
        <Link to="/about">About</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className={`p- font-normal text-mauve ${location.pathname === '/contact' ? 'lg:border-b-2 border-mauve' : ''}`}
        onClick={closeMobileNav}
      >
        <Link to="/contact">Contact</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className={`p- font-normal text-mauve ${location.pathname === '/blog' ? 'lg:border-b-2 border-mauve' : ''}`}
        onClick={closeMobileNav}
      >
        <Link to="/blog">Blog</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className={`p- font-normal text-mauve ${location.pathname === '/gallery' ? 'lg:border-b-2 border-mauve' : ''}`}
        onClick={closeMobileNav}
      >
        <Link to="/gallery">Gallery</Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="sticky top-0 z-10 h-auto lg:h-20 max-w-full rounded-none py-2 px-8 lg:px-24 lg:py-4">
        <div className="flex items-center justify-between text-mauve">
          <Typography
            as="a"
            href="/"
            className="mr-4 cursor-pointer py-1.5 font-medium flex items-center"
          >
            <img src={GMLLogo} alt='logo' className='h-8' />
            <span className='ml-2 text-mauve lg:text-2xl'>Global Missions Link</span>
          </Typography>
          <div className="flex items-center gap-4">
            <div className="hidden lg:block">{navList}</div>
            {/* <Button
              variant="gradient"
              size="sm"
              className="hidden lg:inline-block"
              onClick={closeMobileNav}
            >
              <Link to='/donate' className='flex items-center'>Donate</Link>
            </Button> */}
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>
          {navList}
          {/* <Button variant="gradient" size="sm" fullWidth className="mb-2" onClick={closeMobileNav}>
            <Link to='/donate' className=''>Donate</Link>
          </Button> */}
        </MobileNav>
      </Navbar>
  )
}
