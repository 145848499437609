import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../context/authFirebase';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

export default function Blog() {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, 'posts'), 
      where('category', '==', 'blog'), 
      orderBy('timestamp', 'desc')
    );
    const getBlogData = async () => {
      const data = await getDocs(q);
      setBlogData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBlogData();
  }, []);

  return (
    <div className='px-8 lg:px-24'>
      <h1 className='text-center font-bold text-xl'>Blog</h1>
      {blogData.length > 0 ? (
      <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-5 py-5'>
        {blogData.map((blog) => (
          <div key={blog.id} class="card h-fit overflow-hidden flex flex-col gap-5 max-w-sm bg-white lg:min-w-42 border border-white rounded-lg shadow">
            <img class="rounded-t-lg object-cover " src={blog.image} alt="" />
            <div class="p-3 lg:h-18">
              <h3 class="mb-2 text-xl font-bold ">{blog.title}</h3>
              {/* <p class="mb-3 line-clamp-3 break-all">{parse (blog.body)}</p> */}
              <div class="mb-3 line-clamp-3 break-all" dangerouslySetInnerHTML={{ __html: blog.body}} />
              <div className='flex justify-between items-center'>
                <Link to={`/blog/${blog.id}`} className='text-white'>
                  <button className='bg-mauve px-2 py-1 rounded-full text-sm'>Read More</button>
                </Link>
                <p className='text-mauve text-sm'>{blog.createdAt}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      ) : (
        <div className='flex justify-center items-center h-96'>
          <p className='text-2xl'>No Blog Yet</p>
        </div>
      )}
    </div>
  )
}
