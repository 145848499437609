import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFx2LPH_c2661yJDlQjQ-p0z3J-WQmh4M",
  authDomain: "global-missions-link.firebaseapp.com",
  projectId: "global-missions-link",
  storageBucket: "global-missions-link.appspot.com",
  messagingSenderId: "1073756071578",
  appId: "1:1073756071578:web:513a8a67ba01618757a123",
  measurementId: "G-J7SCHLJ9CQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);