import React, {useState, useEffect} from 'react';
import { Carousel, Typography, Card, CardBody, Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import speech from '../images/speech.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHandHoldingHeart,
  faHandsHoldingChild,
  faChurch,
  faCross,
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';
import gml from '../images/gml.jpeg';
import carousel1 from '../images/carousel1.jpeg';
import carousel2 from '../images/carousel2.jpeg';
import carousel3 from '../images/carousel3.jpeg';
import { db } from '../context/authFirebase';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

export default function Home() {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, 'posts'), 
      where('category', '==', 'blog'), 
      orderBy('timestamp', 'desc')
    );
    const getBlogData = async () => {
      const data = await getDocs(q);
      setBlogData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBlogData();
  }, []);

  return (
    <div className='lg:-mt-20 left-0'>
      {/* 1st layer for home page */}
      <div className='grid place-items-center'>
        {/* Carousel images */}
        <Carousel
          className="top-0 h-72 md:h-96 lg:h-screen w-full"
          transition={{type: "tween", duration: 2}}
          autoplay={{delay: 5000}}
          loop={true}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          <img
            src={gml}
            className="lg:h-screen w-full object-contain md:object-cover top-0 blur-none"
            alt='image1'
          />
          <img
            src={carousel1}
            alt="image2"
            className="lg:h-screen w-full object-contain md:object-cover top-0 blur-none"
          />
          <img
            src={carousel2}
            alt="image3"
            className="lg:h-screen w-full object-contain md:object-cover top-0 blur-none"
          />
          <img
            src={carousel3}
            alt="image3"
            className="lg:h-screen w-full object-contain md:object-cover top-0 blur-none"
          />
        </Carousel>
        {/* Carousel images' text */}
        <div className='absolute w-2/3 md:w-1/2 grid pt-auto px-5 py-3 place-items-center gap-3 md:gap-5 lg:gap-10 text-white lg:px-20 lg:py-10 bg-mauve bg-opacity-50 rounded'>
          <h1 className='font-extrabold text-xl md:text-3xl lg:-mx-20 lg:text-5xl'>Global Missions Link</h1>
          <p className='font-normal text-base lg:text-lg lg:-mx-10 text-center'>Spreading the Gospel of Christ Jesus through Missions, 
            Soul winning, Church Planting and Charitable donations.
          </p>
          <div className='flex text-white gap-2 lg:gap-4'>
            <Link to='/about'>
              <Button variant="outlined" className='text-white border-2 p-2 md:py-3 md:px-5 border-white'>About Us</Button>
            </Link>
            <Link to='/contact'>
              <Button variant="outlined" className='text-white border-2 p-2 md:py-3 md:px-5 border-white'>Contact Us</Button>
            </Link>
          </div>
        </div>  
      </div>

      {/* 2nd Layer for home page */}
      <div className='px-8 py-4 lg:py-0 lg:mx-24 lg:my-5 lg:rounded-lg grid md:gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:items-center bg-gold'>
        {/* image */}
        <div className='grid lg:py-5 lg:w-1/3'>
          <img src={speech} alt="missions" className='object-cover rounded' />
        </div>
        {/* What we do statement */}
        <div className='py-4 lg:py-10 grid justify-items-center gap-2 lg:gap-5 lg:w-1/3 text-white'>
          <h1 className='text-lg md:text-2xl lg:text-3xl font-bold'>Global Missions Link</h1>
          <p className='text-center'>We organise and collaborate with other organizations 
            and Individuals who are passionate about the work of God to do
          </p>
          {/* <div className='flex gap-5'>
            <Link to='/about'>
              <Button variant="outlined" color='white'>About Us</Button>
            </Link>
            <Link to='/contact'>
              <Button variant="outlined" color='white'>Contact Us</Button>
            </Link>
          </div> */}
        </div>
        {/* What we do */}
        <div className='grid md:col-span-2 lg:w-1/4 lg:py-5'>
          <Card className='rounded-none'>
            <CardBody className='flex items-center gap-5'>
              <FontAwesomeIcon icon={faCross} flip style={{"--fa-primary-color": "#ff000d", "--fa-secondary-color": "#ff000d", "--fa-secondary-opacity": "0.3",}} />
              <Typography>Missions</Typography>
            </CardBody>
          </Card>
          <hr/>
          <Card className='rounded-none'>
            <CardBody className='flex items-center gap-5'>
              <FontAwesomeIcon icon={faLightbulb} fade style={{"--fa-primary-color": "#ffdd00", "--fa-secondary-color": "#ffdd00",}} />
              <Typography>Innovation & Training</Typography>
            </CardBody>
          </Card>
          <hr/>
          <Card className='rounded-none'>
            <CardBody className='flex items-center gap-5'>
              <FontAwesomeIcon icon={faChurch} shake style={{"--fa-primary-color": "#2b00ff", "--fa-secondary-color": "#2b00ff",}} />
              <Typography>Church planting</Typography>
            </CardBody>
          </Card>
          <hr/>
          <Card className='rounded-none'>
            <CardBody className='flex items-center gap-5'>
              <FontAwesomeIcon icon={faHandHoldingHeart} beatFade style={{"--fa-primary-color": "#ac1b1b", "--fa-secondary-color": "#ac1b1b",}} />
              <Typography>Charity works</Typography>
            </CardBody>
          </Card>
          <hr/>
          <Card className='rounded-none'>
            <CardBody className='flex items-center gap-5'>
              <FontAwesomeIcon icon={faHandsHoldingChild} bounce style={{"--fa-primary-color": "#143061", "--fa-secondary-color": "#143061",}} />
              <Typography>Helps</Typography>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* 3rd layer*/}
      <div className='px-8 lg:px-0 py-4 lg:py-8 lg:mx-24 lg:my-5'>
        <h1 className='font-bold text-xl text-center'>Core Values</h1>
        <div className='md:flex justify-between text-white text-center'>
          <div className='bg-mauve md:w-1/5 py-5'>
            <p>Faith</p>
          </div>
          <div className='bg-gold md:w-1/5 py-5'>
            <p>Compassion</p>
          </div>
          <div className='bg-mauve md:w-1/5 py-5'>
            <p>Integrity</p>
          </div>
          <div className='bg-gold md:w-1/5 py-5'>
            <p>Excellence</p>
          </div>
          <div className='bg-mauve md:w-1/5 py-5'>
            <p>Collaboration</p>
          </div>
        </div>
      </div>

      {/* 4th layer */}
      <div className='px-8 lg:px-0 py-4 lg:pb-8 lg:mx-24 lg:my-5'>
        <h1 className='font-bold text-xl text-center'>Latest Blog</h1>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-5 py-5'>
          {blogData.slice(0, 4).map((blog) => (
            <div key={blog.id} class="card h-fit overflow-hidden flex flex-col gap-5 max-w-sm bg-white lg:min-w-42 border border-white rounded-lg shadow">
              <img class="rounded-t-lg object-cover " src={blog.image} alt="" />
              <div class="p-3 lg:h-18">
                <h3 class="mb-2 text-xl font-bold ">{blog.title}</h3>
                {/* <p class="mb-3 line-clamp-3 break-all">{parse (blog.body)}</p> */}
                <div class="mb-3 line-clamp-3 break-all" dangerouslySetInnerHTML={{ __html: blog.body}} />
                <div className='flex justify-between items-center'>
                  <Link to={`/blog/${blog.id}`} className='text-white'>
                    <button className='bg-mauve px-2 py-1 rounded-full text-sm'>Read More</button>
                  </Link>
                  <p className='text-mauve text-sm'>{blog.createdAt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
