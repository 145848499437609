import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bhfof4r', 'template_ft3x5dg', form.current, '9_xhzH6d0qL8_WlYq')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <div className='px-8 lg:px-24 bg-white grid gap-14 items-center lg:flex lg:justify-between'>
      <div className='py-5 lg:w-2/5 items-center text-gray-800'>
        <h1 className='font-bold text-xl md:text-3xl pb-5 md:pb-10'>For Prayer, Counselling & Donations <span className=''>contact</span></h1>
        <div className='flex pb-5 md:pb-10 gap-5'>
          <FontAwesomeIcon bounce className='pt-1 md:pt-2' icon={faSquarePhone} style={{color: "#111827",}} />
          <div>
            <h1 className='font-bold md:text-2xl'>Phone Number</h1>
            <p className='md:text-lg'>+233 244021771</p>
            <p className='md:text-lg'>+233 240502834</p>
            <p className='md:text-lg'>+233 544966829</p>
          </div>
        </div>
        <hr className='bg-gray-900' />
        <div className='flex pt-5 md:pt-10 gap-5'>
          <FontAwesomeIcon bounce className='pt-1 lg:fa-xl md:pt-2' icon={faEnvelope} style={{color: "#111827",}} />
          <div>
            <h1 className='font-bold md:text-2xl'>Email</h1>
            <p className='md:text-lg'>globalmissionslink@gmail.com</p>
          </div>
        </div>
      </div>
      {/* Form */}
      <div className='lg:w-2/5 py-5'>
        <form ref={form} onSubmit={sendEmail} className='grid gap-4'>
          <input className='p-2 border border-gray-500 rounded' type="text" placeholder='Name' name='from_name' required />
          <div className='lg:flex grid gap-4 lg:gap-8'>
            <input className='p-2 border border-gray-500 rounded' type="text" placeholder='Email' name='from_email' required />
            <input className='p-2 border border-gray-500 rounded' type='tel' placeholder='Phone Number' name='phone' required />
          </div>
          <input className='p-2 border border-gray-500 rounded' type="text" placeholder='Subject' name='from_subject' required />
          <textarea className='p-2 border border-gray-500 rounded' name="message" cols="30" rows="10" placeholder='Message' required></textarea>
          <button type='submit' class="flex justify-center items-center gap-10 bg-mauve text-white py-2 px-4">
            Send
            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
              <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
            </svg>
          </button>
        </form>
      </div>
    </div>
  )
}
