import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { db } from '../context/authFirebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../context/authFirebase';
import { useNavigate, Link, useParams } from 'react-router-dom';

export default function EditPost() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const { id } = useParams();

  // Handle title
  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  // Handle body
  const handleBody = (e) => {
    setBody(e);
  }

  // Handle sign out
  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate('/login')
    }).catch((error) => {
      console.log(error.message);
    });
  }

  // Auth state change
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user);
      } else {
        // User is signed out
        navigate('/login');
      }
    });

    // Fetch data to edit
    const fetchPost = async () => {
      try {
        const postRef = doc(db, 'posts', id); // Reference the 'posts' collection and the specific post by ID
        const docSnap = await getDoc(postRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.title);
          setBody(data.body);
        } else {
          console.log('Post not found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    }

    fetchPost();
  }, [navigate, id]);

  // Update post
  const updatePost = async () => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    try {
      const postRef = doc(db, 'posts', id);
      await updateDoc(postRef, {
        title: title,
        body: body,
        createdAt: currentDate,
        timestamp: serverTimestamp()
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  // Logout
  const logout = () => {
    handleSignOut();
  }

  return (
    <div className="mx-auto relative font-sans">
      <div className='h-12 lg:h-20 w-full bg-mauve flex justify-center md:justify-end px-8 lg:px-20'>
        <ul class="flex items-center text-white gap-10 justify-between mt-0 rounded-lg md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
          {currentUser ? (
            <>
            <span className="block py-2 pl-3 pr-4 md:text-blueblack  md:border-0 md:p-0 ">{currentUser.email}</span>
            <button onClick={logout} className='block py-2 md:px-4 rounded-3xl pl-3 pr-4 bg-gold md:text-white md:hover:bg-seablue'>Logout</button>
            </>
          ) : (
            <Link to="/login"></Link>
          )}
        </ul>
      </div>

      <div class="px-5 lg:px-20 py-10 lg:py-10">
        <Link to="/dashboard">
          <button class='mb-5 lg:mb-8 py-2 px-4 bg-blue-800 hover:bg-blue-600 rounded text-white font-semibold'>&#129048; Back</button>
        </Link>

        <div className='grid gap-10 lg:flex lg:justify-between mx-auto lg:mb-10 text-blueblack'>
        {/* Title & Content */}
        <div className='lg:h-auto lg:w-2/3 grid gap-5'>
          {/* Title */}
          <div className='w-full'>
            <input 
              className='w-full p-2 border border-gray-400 bg-transparent'
              type="text"
              placeholder='Title'
              value={title}
              onChange={handleTitle}
              id='title'
            />
          </div>
          {/* Content */}
          <div className=''>
            <ReactQuill 
              className='md:h-40'
              theme="snow"
              value={body}
              id='body'
              onChange={handleBody}
            />
          </div>
    
          <button 
            className='bg-blue-800 hover:bg-blue-600 text-white font-bold lg:mt-10 mt-2 py-2 px-4 rounded-sm disabled:bg-blue-300 disabled:cursor-not-allowed'
            onClick={updatePost}
          >Update
          </button>
        </div>
      </div>
      </div>
    </div>
  )
}
