import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../context/authFirebase';
import { collection, doc, getDocs, deleteDoc, query, where, orderBy } from 'firebase/firestore';

export default function Dashboard() {
  const [currentUser, setCurrentUser] = useState(null);
  const [posts, setPost] = useState([]);
  const navigate = useNavigate();

  // Fetch post
  const fetchPost = async () => {
    const q = query(
      collection(db, 'posts'),
      where('category', '==', 'blog'),
      orderBy('timestamp', 'desc'),
    );
    const data = await getDocs(q);
    setPost(data.docs.map((doc) => ({ 
      ...doc.data(), 
      id: doc.id 
    })));
  }

  // Handle sign out
  const logout = () => {
    signOut(auth).then(() => {
      navigate('/login')
    }).catch((error) => {
      console.log(error.message);
    });
  }

  // Auth state change
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user);
        fetchPost();
      } else {
        // User is signed out
        navigate('/login');
      }
    });
  }, [navigate]);

  // Delete post
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'posts', id));
      fetchPost();
      setPost(posts.filter((post) => post.id !== id));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  }

  return (
    <div>
      <div className='h-12 lg:h-20 w-full bg-mauve flex justify-center md:justify-end px-8 lg:px-20'>
        <ul class="flex items-center text-white gap-10 justify-between mt-0 rounded-lg md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
          {currentUser ? (
            <>
            <span className="block py-2 pl-3 pr-4 md:text-blueblack  md:border-0 md:p-0 ">{currentUser.email}</span>
            <button onClick={logout} className='block py-2 md:px-4 rounded-3xl pl-3 pr-4 bg-gold md:text-white md:hover:bg-seablue'>Logout</button>
            </>
          ) : (
            <Link to="/login"></Link>
          )}
        </ul>
      </div>

      <div className="mt-5 lg:mt-10 mx-8 lg:mx-20">
        <h2 className="text-2xl font-bold mb-4">Global Missions Link Posts</h2>
        <Link class="bg-blue-800 hover:bg-blue-600 px-4 py-2 text-white font-bold rounded" to="/add-post">Add New Post</Link>
          <ul class="mt-4">
            {posts.map((post, index) => (
              <li 
              key={post.id}
              className={`mb-1 py-2 px-2 grid gap-2 lg:flex lg:justify-between ${index % 2 === 0 ? 'bg-gray-400' : 'bg-gray-300'}`}
              >
                <span className="mr-2"><b>{post.title}</b> - {post.createdAt}</span>
                <div class="space-x-4">
                  <button
                    className="lg:ml-2 font-semibold text-red-600"
                    onClick={() => handleDelete(post.id)}
                  >
                    Delete
                  </button>
                  <Link to={`/edit-post/${post.id}`}>
                    <button className="ml-2 font-semibold text-blue-600">Edit</button>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
      </div>
    </div>
  )
}
