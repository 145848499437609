import React from 'react';
import GMLLogo from '../images/gml.png';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSquareFacebook, 
  faSquareInstagram, 
  faSquareYoutube, 
  faLinkedin, 
  faSquareTwitter,
  faTiktok
  } 
  from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


export default function Footer() {
  return (
    <footer className='bg-mauve px-8 lg:px-24 pt-16 pb-4'>
      <div className='grid'>
        {/* Top */}
        <div className='grid lg:flex justify-between gap-16 lg:gap-0'>
          {/* Left */}
          <div className='grid gap-2'>
            <div className='grid gap-2'>
              <div className='flex items-center gap-4'>
                <img src={GMLLogo} alt='logo' className='h-14' />
                <p className='text-white font-semibold text-xl'>Global Missions  <br/> <span>Link</span></p>
              </div>
              <div>
                <p className='text-xs text-white'>Visit us on all social media handles</p>
              </div>
            </div>
            <div className='flex gap-4'>
              <a href='https://www.linkedin.com/company/global-missions-link/' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faLinkedin} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>LinkedIn</span>
              </a>
              <a href='https://www.facebook.com/missions.gml' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faSquareFacebook} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>Facebook</span>
              </a>
              <a href='https://instagram.com/globalmissionslink?igshid=OGY3MTU3OGY1Mw==' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faSquareInstagram} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>Instagram</span>
              </a>
              <a href='https://twitter.com/g_missionslink' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faSquareTwitter} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>Twitter</span>
              </a>
              <a href='http://tiktok.com/@globalmissionslink' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faTiktok} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>Tiktok</span>
              </a>
              <a href='https://www.youtube.com/@globalmissionslink' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faSquareYoutube} style={{color: "#ffffff",}} className='hover:-rotate-12' />
                <span className='sr-only'>Youtube</span>
              </a>
            </div>
          </div>
          {/* Right */}
          <div className='grid md:flex gap-16 md:12'>
            <div className='text-white grid gap-4'>
              <h1 className='text-white font-bold'>Global Missions Link</h1>
              <div className='grid'>
                <Link to='/about' className='hover:underline'>About Us</Link>
                <Link to='/contact' className='hover:underline'>Contact Us</Link>
                <Link to='/blog' className='hover:underline'>Blog</Link>
                <Link to='/gallery' className='hover:underline'>Gallery</Link>
                {/* <Link to='/donate'>Donate</Link> */}
              </div>
            </div>
            <div className='text-white grid gap-4'>
              <h1 className='font-bold'>Contact Us</h1>
              <div className='grid'>
                <p>Global Missions Link</p>                
                <p>Whatsapp: <a href='https://wa.me/244021771' className='hover:underline' target="_blank" rel="noreferrer">+233 24 402 1771</a> </p>
                <p>Email: <a href='mailto:globalmissionslink@gmail.com' className='hover:underline' target='_blank' rel='noreferrer'>
                     globalmissionslink@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className='my-4' />
        {/* Bottom */}
        <div className='flex justify-center'>
          <div className=''>
            <p className='text-white text-center text-sm font-thin'>© {moment().format('YYYY')}  Developed with 💖 by 
              <a href="https://www.eitghana.com" target="_blank" rel="noreferrer"> EIT Ghana Limited</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
