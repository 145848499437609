import React, { useEffect, useState} from 'react';
import { db } from '../context/authFirebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default function Gallery() {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'posts'), where('category', '==', 'gallery'));
    const getBlogData = async () => {
      const data = await getDocs(q);
      setBlogData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBlogData();
  }, []);

  return (
    <div className='px-8 lg:px-24'>
      <h1 className='text-center font-bold text-xl'>Gallery</h1>
      {blogData.length > 0 ? (
      <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-5 py-5'>
        {blogData.map((blog) => (
          <div key={blog.id} class="card h-fit overflow-hidden flex flex-col gap-5 max-w-sm bg-white lg:min-w-42 border border-white rounded-lg shadow">
            <img class="object-cover " src={blog.image} alt={blog.title} />
          </div>
        ))}
      </div>
      ) : (
        <div className='flex justify-center items-center h-96'>
          <p className='text-2xl'>No Gallery Yet</p>
        </div>
      )}
    </div>
  )
}
