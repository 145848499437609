import React from 'react';
import stephen from '../images/stephen.jpeg';
import ebenezer from '../images/ebenezer.jpeg';
import rosemond from '../images/rosemond.jpg';
import ekow from '../images/ekow.jpeg';
import vincent from '../images/vincent.jpeg';
import sandra from '../images/sandra.jpeg';
import abraham from '../images/abraham.jpeg';

export default function About() {
  return (
    <div className='px-8 lg:px-24'>
      {/* Vision & Mission Statement ::: Core Values */}
      <div className='pb-5'>
        <div className='md:flex grid gap-5 md:justify-between py-5'>
          {/* Vision Statement */}
          <div className='grid md:w-2/5 p-5 gap-5 bg-blue-gray-50'>
            <h1 className='font-bold text-xl'>Vision Statement</h1>
            <p>To preach, spread the gospel, plant churches and care for the needy around the world.</p>
          </div>
          {/* Mission Statement */}
          <div className='grid md:w-2/5 p-5 gap-5 bg-blue-gray-50'>
            <h1 className='font-bold text-xl'>Mission Statement</h1>
            <p>To proclaim the gospel of Jesus Christ: the anointed and His anointing every day.</p>
          </div>
        </div>
        {/* Core Values */}
        <div className=''>
          <h1 className='font-bold text-xl text-center'>Core Values</h1>
          <div className='md:flex justify-between text-white text-center'>
            <div className='bg-mauve md:w-1/5 py-5'>
              <p>Faith</p>
            </div>
            <div className='bg-gold md:w-1/5 py-5'>
              <p>Compassion</p>
            </div>
            <div className='bg-mauve md:w-1/5 py-5'>
              <p>Integrity</p>
            </div>
            <div className='bg-gold md:w-1/5 py-5'>
              <p>Excellence</p>
            </div>
            <div className='bg-mauve md:w-1/5 py-5'>
              <p>Collaboration</p>
            </div>
          </div>
        </div>
      </div>
      {/* Team */}
      <div className='pb-5'>
        <h1 className='text-center font-bold text-xl'>Meet Our Team</h1>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 text-white lg:justify-center gap-5'>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={stephen} alt="Stephen Agyare" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Stephen Agyare</h3>
              <p className="mb-3 ">General Overseer</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={ebenezer} alt="Ebenezer Dadzie" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Ebenezer Dadzie</h3>
              <p className="mb-3 ">Missions and Administrative Director</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={rosemond} alt="Rosemond Esi Abakah" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Rosemond Esi Abakah</h3>
              <p className="mb-3 ">Finance and Logistics Director</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={ekow} alt="Ekow Oboh Yamoah" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Ekow Oboh Yamoah</h3>
              <p className="mb-3 ">Digital, Prayer and Communication Director</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={vincent} alt="Vincent Deroxyl Eduful" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Vincent Deroxyl Eduful</h3>
              <p className="mb-3 ">Christian Education and Innovation Director</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={sandra} alt="Sandra Ama Mother" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Sandra Ama Morcher</h3>
              <p className="mb-3 ">Christian Social Responsibility Director</p>
            </div>
          </div>
          <div className="card h-fit overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-mauve lg:min-w-42 border border-white rounded-lg shadow">
            <img className="rounded-t-lg object-cover " src={abraham} alt="Abraham Kwaku Agbebiam" />
            <div className="p-3 lg:h-18">
              <h3 className="mb-2 text-xl font-bold ">Abraham Kwaku Agbebiam</h3>
              <p className="mb-3 ">Overseer for Europe</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
